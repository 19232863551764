const MobileLearnings = () => {

    return <>
        <div className="lg:mx-auto" >
            <div className="mx-auto w-full">
                <div className="h-full mt-20 flex justify-center items-center">
                        Coming soon...
                </div>
            </div>
        </div>
    </>
}

export default MobileLearnings
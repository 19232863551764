
import './App.css';
import Layout from './pages/Layout'
import React, { useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from './pages/About';
import ProgramPage from './pages/ProgramPage';
import { modelContext } from './Provider';
import { Modal } from 'flowbite-react';
import Schedule from './pages/sections/Schedule';
import MobileHome from './pages/mobile/MobileHome';
import MobileAbout from './pages/mobile/MobileAbout';
import MobileNotifications from './pages/mobile/MobileNotifications';
import MobileLearnings from './pages/mobile/MobileLearnings';

const App = () => {

  const [model, setModel] = useContext(modelContext);

  const getContent = (model) => {
    if(model.type) {
      if(model.type === 'iframe') {
          return <iframe className={'pdf'} width="100%" height="95%" frameborder="0" src={'zh-registration.htm'} />
      } else if(model.type === 'img') {
          return <img className='lg:w-full' src={model.img} />
      } else if(model.type === 'content') {
          return <div>
            <div className="text-black text-md flex font-semibold my-1 items-center">{model.title}</div>
            <div className="text-black text-sm font-extralight flex my-1 items-center">{model.msg}</div>
          </div>
      }
    }
    return <></>
  }

  return (
    <BrowserRouter >
      <div className='default-font h-screen' id='top'>
        <Routes>
          <Route path="/about" element={<About />}/>
          <Route path="/little-tiger-program" element={<ProgramPage page={'little_tiger_program'} />} />
          <Route path="/youth-program" element={<ProgramPage page={'youth_program'} />} />
          <Route path="/teen-adult-program" element={<ProgramPage page={'teen_adult_program'} />} />
          <Route path="/family-program" element={<ProgramPage page={'family_program'} />} />
          <Route path="/app/about" element={<MobileAbout />} />
          <Route path="/app/home" element={<MobileHome />} />
          <Route path="/app/schedule" element={<Schedule />} />
          <Route path="/app/notifications" element={<MobileNotifications />} />
          <Route path="/app/learnings" element={<MobileLearnings />} />
          <Route path="/*" element={<Layout />}/>
        </Routes>
        <Modal className='z-[1000] h-full pt-20 sm:pt-0' show={model.show} size={'7xl'} onClose={() => setModel({ show: !model.show, type: null})}>
          <Modal.Body className={`h-full ${model.type && model.type === 'iframe' ? 'h-screen' : 'h-full'}`}>
            <div className='flex justify-end hover:cursor-pointer' onClick={() => setModel({ show: !model.show, type: null})}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} className="h-5 w-5" > <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /> </svg>
            </div>
            <div className={`space-y-6 p-6 ${model.type && model.type === 'iframe' ? 'h-screen' : 'h-full'} flex justify-center items-center`}>
                {getContent(model)}
            </div>
          </Modal.Body>
      </Modal>
      </div>
    </BrowserRouter>
  );
}

export default App;
import Pagewrapper from "../../component/pagewrapper/Pagewrapper"
import Events from "../sections/Events"
import Programs from "../Contact"
import MobileNavbar from "./MobileNavbar"
import Contact from "../Contact"

const MobileHome = () => {

    return <div>
        <Pagewrapper>
            <Events  showHeader={true}/>
        </Pagewrapper>
    </div>
}

export default MobileHome